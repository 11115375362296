body {
  font-family: 'Droid Sans', Arial, sans-serif;
  /*font-family: 'Lato', Arial, sans-serif;*/
  font-weight: 400;
  color: #363636; }

h1, h2, h3 {
  font-family: 'Open Sans Condensed', Arial, sans-serif;
  color: #060606; }

h1 {
  font-size: 32px;
  border-left: 16px solid #ffed00;
  padding-left: 10px;
  line-height: 32px; }

h2 {
  font-size: 22px;
  line-height: 32px;
  margin-top: 10px;
  margin-bottom: 14px; }

h3 {
  font-size: 16px; }

p {
  margin-bottom: 14px;
  text-align: justify; }

p span.bold {
  font-weight: 700; }

a, a:hover, a:visited {
  color: #000;
  text-decoration: underline; }

.img-responsive {
    margin: 0 auto;
}

.container {
  max-width: 970px; }

.navbar {
  margin-bottom: 0;
  min-height: 44px; }

.navbar-inverse {
  background: -webkit-linear-gradient(top, #626260 0, #4d4d4c 100%) no-repeat;
  background: linear-gradient(to bottom, #626260 0, #4d4d4c 100%) no-repeat; }

.navbar-inverse .navbar-nav > li > a {
  color: #fff;
  text-decoration: none; }

.navbar-inverse .navbar-nav > li.active {
  background-color: #787876; }

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
  background-color: #787876; }

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  background-color: #6e6e6c; }

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 12px;
    padding-bottom: 12px; } }

@media (min-width: 768px) {
  .navbar .navbar-nav {
    display: inline-block;
    float: none;
    vertical-align: top; }
  .navbar .navbar-collapse {
    text-align: center; } }

@media (max-width: 992px) {
  .nav>li>a {
    padding: 12px 10px;
  }
}

.header {
  position: absolute;
  background: url('/img/header_innovagroup.png') center;
  height: 72px;
  width: 100%;
  margin-top: 12px;
  display: none;
}

.slider {
  width: 100%;
  height: 492px;
  overflow: hidden;
  margin-top: 45px;
  margin-bottom: 15px;
}

@media (max-width: 992px) {
  .slider {
    height: 392px;
  }
}

@media (max-width: 768px) {
  .slider {
    height: 292px;
  }
}

#maximage {
  position: relative !important; }

.content {
  min-height: 250px; }

footer {
  background: -webkit-linear-gradient(top, #565654 0, #494948 100%) no-repeat;
  background: linear-gradient(to bottom, #565654 0, #494948 100%) no-repeat;
  margin-top: 30px;
  color: #fff;
  border-top: 1px solid #080808; }

footer a, footer a:hover, footer a:visited {
  color: #ffed00; }

footer span.slogan {
  display: block;
  font-family: 'Open Sans Condensed', Arial, sans-serif;
  font-size: 18px;
  margin-top: 10px; }

footer div.footer-left {
  margin-top: 60px; }

footer div.footer-right {
  margin-top: 33px; }

#socialshareprivacy {
  margin-top: 46px; }

img.profil {
  margin-top: 90px; }

@media (max-width: 768px) {
  img.profil {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.underline {
  text-decoration: underline; }

.margintop20 {
  margin-top: 20px; }

.marginbottom20 {
  margin-bottom: 20px; }

.content ul {
  padding-left: 5px;
  list-style-type: none;
  line-height: 18px;
  margin-bottom: 20px; }

.content ul li:before {
  color: #ffed00;
  content: '\25AA';
  position: relative;
  left: -5px;
  top: 5px;
  font-size: 30px;
  margin-left: -12px; }

.content ul li {
  margin-left: 15px; }

.content ul.marketing {
  margin-bottom: 0px; }

.content ul.philosophie {
  padding-top: 5px;
  margin-bottom: 0px; }

.panel-innova {
  background-color: #e6e6e6; }

.panel-innova > .panel-heading {
  font-family: 'Open Sans Condensed', Arial, sans-serif;
  color: #060606;
  font-size: 16px; }

.panel-innova > .panel-body ul {
  margin-top: -10px; }

.jobs h2 {
  font-size: 16px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  color: #363636; }

.jobs a {
  text-decoration: none; }

.jobs a, .jobs a:visited {
  color: #363636; }

.jobs span.headline {
  text-transform: uppercase;
  font-weight: 700; }

.jobs span.description {
  display: block;
  margin-bottom: 15px; }

.jobs ul {
  padding-left: 5px;
  list-style-type: none;
  line-height: 18px;
  margin-bottom: 20px; }

.jobs ul li:before {
  color: #ffed00;
  content: '\25AA';
  position: relative;
  left: -5px;
  top: 5px;
  font-size: 30px; }

.jobs ul li {
  margin-left: 15px;
  margin-bottom: 20px; }

.img-stellenangebot {
  margin-bottom: 10px;
}

.img-stellenangebot:last-child {
  margin-bottom: 0px;
}

.btn-innova {
  color: #363636;
  background-color: #ffed00;
  border-color: #787876; }

.ltr {
  direction: rtl;
  unicode-bidi: bidi-override; }

.small {
  font-size: 12px; }

#gmap {
  width: 100%;
  height: 475px; }

p.form-error {
  color: #ff0000; }